document.documentElement.className = document.documentElement.className.replace(/\bno-js\b/g, "") + "js"

if (window.location.hash) { setTimeout(function () { window.scrollTo(0, 0); }, 2); }

jQuery(document).ready(function ($) {

    /*-----------------------------------------------------------------------------GLOBAL ON LOAD----*/

    var LazyLoading = (function () {
        var instance = new LazyLoad();

        function lazyBGImages() {
            var $bgImages = $('[data-bg]:not(.lazy)');
            var $mobileBG = $('[data-bg-mobile]');

            if ($mobileBG.length && window.innerWidth < 480) {
                $mobileBG.each(function () {
                    this.dataset.bg = this.dataset.bgMobile;
                });

                $bgImages = $('[data-bg]:not(.lazy)');
            }

            if ($bgImages.length) {
                $bgImages.each(function () {
                    $(this).addClass('lazy');
                });
            }
            instance.update();
        }

        function update() {
            lazyBGImages();
        }

        lazyBGImages();

        var MobileVideos = (function () {
            var $mobileVid = $('video[data-mobile-vid]');
            if (!$mobileVid.length) { return; }

            if ($mobileVid.length && window.innerWidth < 480) {
                $mobileVid.each(function () {
                    if (this.dataset.mobileVid == '') { return false; }

                    this.dataset.src = this.dataset.mobileVid;
                    $(this).parent().removeClass('desk-only').addClass('bg-video--mobile');
                    instance.update();
                });
            }
        }());

        return {
            update: update
        }
    }());

    window.Cookie = (function () {
        /**
         * get cookie value
         * @param   {String} strName - cookie name
         * @returns {String}         - cookie value
         */
        var get = function (strName) {
            // seperate cookies into array
            var arrCookies = decodeURIComponent(document.cookie).split("; ");
            // initiate cookie value return
            var strCookieValue;
            // remove whitespace
            strName = strName.trim();

            // cycle through cookies
            arrCookies.forEach(function (strCookie) {
                // find cookie and return value
                if (strCookie.indexOf(strName + "=") === 0) {
                    strCookieValue = strCookie.split('=')[1];
                }
            });

            // if cookie value is undefined
            if (!strCookieValue) console.warn("Cookie '" + strName + "' does not exist");
            // return cookie value
            return strCookieValue;

        };
        /**
         * Set cookies
         * @param {String} strName        - cookie name
         * @param {String} strValue       - cookie value
         * @param {Number} [intExpInDays] - days until cookie expires
         * @param {String} [strPath]      - path where cookie is accessible
         */
        var set = function (strName, strValue, intExpInDays, strPath) {
            var strExpires = "";
            /* if strPath is undefined
             * default to domain root */
            strPath = strPath || "/";

            // if intExpInDays is defined
            if (intExpInDays) {
                var objExpireDate = new Date();
                // set time to expiration date
                objExpireDate.setTime(objExpireDate.getTime() + (intExpInDays * 24 * 60 * 60 * 1000));
                // format to cookie param
                strExpires = "expires=" + objExpireDate.toUTCString() + ";";
            }
            // set cookie
            document.cookie = strName.trim() + "=" + strValue + ";" + strExpires + "path=" + strPath;
        };

        /**
         * remove cookie
         * @param {String} strName - cookie name
         */
        var remove = function (strName) {
            // if cookie exists - set expiration to day before
            if (get(strName) !== undefined) set(strName, '_', -1);
        };

        return {
            get: get,
            set: set,
            delete: remove,
        };
    })();

    var AjaxSearch = (function () {
        var $searchPop = $('.searchpop');
        if (!$searchPop.length) { return; }

        var delay;
        var $searchBtn = $('.sitesearch');
        var $input = $searchPop.find('[type="text"]');
        var $clear = $searchPop.find('[type="reset"]');
        var $close = $searchPop.find('.searchpop__close');
        var $results = $searchPop.find('.searchpop__results');
        var $suggested = $searchPop.find('.searchpop__suggested');

        var exclude_keys = [16, 18, 20, 27, 37, 38, 39, 40, 46];

        $searchBtn.click(function () {
            $searchPop.addClass('active');
            $input.focus();
            // hide suggested
        });

        $close.click(function () {
            $searchPop.removeClass('active');
            $input.value('');
            $suggested.show();
            $results.html('');
        });

        $clear.click(function (e) {
            $suggested.show();
            $input.value('');
            $results.html('').hide();

        });

        $('#ajaxsearch').submit(function (e) {
            e.preventDefault();
        });

        $input.keyup(function (e) {
            var _this = this;
            clearTimeout(delay);
            if (exclude_keys.indexOf(e.keyCode) > -1) { return; }

            if (_this.value == '') {
                $suggested.show();
                $results.html('');
                return;
            }

            delay = setTimeout(function () {

                var data = {
                    action: 'search',
                    term: _this.value
                };

                $.ajax({
                    url: ajaxURL,
                    type: 'post',
                    data: data,
                    beforeSend: function () {
                        $results.addClass('loading');
                        $suggested.hide();
                    },
                    success: function (res) {
                        console.log(res);
                        $('.searchpop__results').html(res);
                        $results.removeClass('loading');
                    }
                });
            }, 500);

        });
    }());

    var SmoothScroll = (function () {
        var $anchorLinks = $('a[href^="#"]').not('a[href="#"]');

        $('a[href="#"]').click(
            function (e) { e.preventDefault(); return; }
        );

        function goTo(target) {
            if (target === "" || !$(target).length) { return; }
            var scrollPos = typeof target === 'number' ? target : $(target).offset().top;

            if (window.innerWidth >= 720) {
                scrollPos -= $('header').outerHeight();
            } else {
                scrollPos -= $('header').outerHeight() * 2;
            }

            $('html, body').stop().animate({
                'scrollTop': scrollPos - 32
            }, 1200, 'swing', function () {
                if (typeof target === 'string') {

                    if (window.location.hash) {
                        // window.location.hash = target;
                    }
                }
            });
        }

        if (window.location.hash) {
            setTimeout(function () {
                goTo(window.location.hash);
            }, 500);
        }

        if ($anchorLinks.length) {
            $anchorLinks.on('click', function (e) {
                if (!$("#" + this.hash.replace('#', '')).length) { return; }
                e.preventDefault();
                goTo(this.hash);
            });
        }

        return { to: goTo }
    }());

    //Global function to toggle simple accordions
    window.Accordions = (function () {
        var $accordions = $('.accordion');
        if (!$accordions.length) { return; }

        function update() {
            $('.accordion.active').each(function () {
                $(this).children('.accordion__content').slideDown('fast');
            });
        }

        $('body').on('click', '.accordion__label', function (e) {
            var $this = $(this);
            var $accordion = $this.parent();
            var $content = $accordion.children('.accordion__content');
            var $siblings = $accordion.siblings();

            if ($accordion.hasClass('active')) {
                $accordion.removeClass('active');
                $content.slideUp('fast');
            } else {
                $accordion.addClass('active');
                $siblings.removeClass('active').children('.accordion__content').slideUp('fast');
                $content.slideDown('fast');
            }
        });
        update();
        return { update: update }

    }());

    var Forms = (function () {
        var InputMasks = (function () {
            var $masks = $('[data-mask]');
            if (!$masks.length) { return; }

            /**
             * Key Codes:
             * 8    - backspace
             * 13   - enter
             * 16   - shift
             * 18   - alt
             * 20   - caps
             * 27   - esc
             * 37   - left arrow
             * 38   - up arrow
             * 39   - right arrow
             * 40   - down arrow
             * 46   - delete
             **/
            var exclude_keys = [8, 13, 16, 18, 20, 27, 37, 38, 39, 40, 46];

            $('[data-mask]').keyup(function (e) {
                console.log(e.keyCode);
                if (exclude_keys.indexOf(e.keyCode) > -1) { return; }

                switch (this.dataset.mask) {
                    case 'digits':
                        var x = this.value.replace(/\D/g, '');
                        this.value = x;
                        break;
                    case 'phone':
                        var x = this.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                        console.log(x);
                        this.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
                        break;
                    case 'ssn': {
                        var x = this.value.replace(/\D/g, '').match(/^(\d{0,3})(\d{0,2})(\d{0,4})/);
                        this.value = !x[2] ? x[1] : x[1] + '-' + x[2] + '-' + x[3];
                    }
                }
            });
        }());

        //Plugin used for form validation
        var parselyOptions = {
            classHandler: function (parsleyField) {
                var $element = parsleyField.$element;
                if ($element.parent().hasClass('select-menu')) {
                    return $element.parent();
                }
                return $element;
            },
            errorsContainer: function (parsleyField) {
                var $element = parsleyField.$element;
                var $fieldContainer = $element.closest('.form-field');
                if ($fieldContainer) {
                    return $fieldContainer;
                }
            }
        };

        //Global function to set form state classes
        var formStates = (function () {
            $formInputs = $('form :input');
            if (!$formInputs.length) { return; }

            $formSelectMenus = $('.select-menu select, .ginput_container_select select');

            function isGFormInput($el) {
                return $el.parent().hasClass('ginput_container') ? $el.parent().parent() : $el;
            }

            function setFilled($input) {
                $input.addClass('filled');
            }

            function removeFilled($input) {
                $input.removeClass('filled');
            }

            function setFocused() {
                $(this).addClass('focused');
            }

            function removeFocused() {
                $(this).removeClass('focused');
            }

            function checkInput(e) {
                if (this.type == 'button' ||
                    this.type == 'range' ||
                    this.type == 'submit' ||
                    this.type == 'radio' ||
                    this.type == 'checkbox' ||
                    this.type == 'reset') { return; }

                var $this = $(this);
                var $parent = $this.parent();
                var is_selectMenu = $parent.hasClass('select-menu') || $parent.hasClass('ginput_container_select');

                var $input = is_selectMenu ? $parent : $this;

                switch (this.type) {
                    case 'select-one':
                    case 'select-multiple':
                        if (this.value !== '') {
                            setFilled($input);
                        } else {
                            removeFilled($input);
                        }
                        break;
                    default:
                        if (this.value !== '') {
                            setFilled($input);
                        } else {
                            removeFilled($input);
                        }
                        break;
                }
            }

            $formInputs.each(checkInput);
            $formInputs.on('change', checkInput);
            $formInputs.on('focus', setFocused);
            $formInputs.on('blur', removeFocused);
            $formSelectMenus.on('focus', setFocused);
            $formSelectMenus.on('blur', removeFocused);

        });
        return { options: parselyOptions }
    }());

    //Global function top open / close lightboxes
    var PDMLightbox = (function () {
        //Lightbox reset - This lightbox is empty and present on all pages
        var $lightbox = $('.pdm-lightbox--reset');

        //it's content can be filled from various sources
        //after close, the content is wiped
        var $lightbox_content = $('.pdm-lightbox--reset .pdm-lightbox__content');

        $('body').on('click', '[data-lightbox-iframe],[data-lightbox-content],[data-lightbox-target],.lightbox-trigger', function (e) {

            e.preventDefault();

            var iframe = $(this).data('lightbox-iframe');

            if (iframe) {
                var youtubePattern = /(?:http?s?:\/\/)?(?:www\.)?youtu(be\.com\/|\.be\/)(embed\/(.+)(\?.+)?|watch\?v=(.+)(\&.+)?)/g;
                var vimeoPattern = /(?:http?s?:\/\/)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/g;

                if (youtubePattern.test(iframe)) {
                    classes += ' youtube-vid'

                    replacement = '<div class="spacer"><iframe width="560" height="315" frameborder="0" allowfullscreen src="//www.youtube.com/embed/$3?rel=0&autoplay=1&modestbranding=1&iv_load_policy=3" /></div>';

                    iframe = iframe.replace(youtubePattern, replacement);

                }

                if (vimeoPattern.test(iframe)) {

                    classes += ' vimeo-vid'

                    replacement = '<div class="spacer"><iframe width="560" height="315" frameborder="0" allowfullscreen src="//player.vimeo.com/video/$3?rel=0&autoplay=1&modestbranding=1&iv_load_policy=3" /></div>';

                    iframe = iframe.replace(vimeoPattern, replacement);

                }

                $lightbox_content.html('<div class="video-embed">' + iframe + '</div>');
            } else {
                if ($(this).data('lightbox-content')) {
                    var content = $(this).data('lightbox-content');
                } else if ($(this).data('lightbox-target')) {
                    var target = $(this).data('lightbox-target');
                    var content = $('#' + target).html();
                } else {
                    var content = $(this).next('.lightbox-content').html();
                }
                $lightbox_content.html(content);
            }

            var classes = $(this).data('lightbox-classes');
            LazyLoading.update();
            $lightbox.addClass('active').addClass(classes);

        });

        function closeModal($lightbox) {
            $lightbox.removeClass('active');
            $('body').removeClass('noScroll');
            //wait before removing classes till lightbox closses
            if ($lightbox.hasClass('pdm-lightbox--reset')) {
                setTimeout(function () {
                    $lightbox.find('.pdm-lightbox__content').empty();
                    $lightbox.attr('class', 'pdm-lightbox pdm-lightbox--reset');
                }, 750);
            }
        }

        function openModal($lightbox) {
            $lightbox.addClass('active');
            $('body').addClass('noScroll');
        }

        function updateModal($lightbox, $content) {
            $lightbox.find('.pdm-lightbox__content').html($content);
        }

        $('.pdm-lightbox').on('click', function (e) {
            if (e.target == this) {
                closeModal($(this));
            }
        });

        $('.pdm-lightbox__close').click(function (e) {
            e.stopPropagation();
            closeModal($(this).closest('.pdm-lightbox'));
        });
        return {
            close: closeModal,
            open: openModal,
            update: updateModal
        };
    }());

    //******************************************************Everything under this is optional, feel free to delete

    var Header = (function () {
        var $body = $('body');
        var $header = $('header.gheader');
        var $nav = $header.find('nav.global');
        var $adminBar = $('#wpadminbar');
        var $mainTop = $('.megamenu-submenu-top--main');

        var header_height = $header.innerHeight();
        if ($adminBar.length) { header_height += $adminBar.innerHeight(); }
        /*   if (window.innerWidth < 960) { $nav.css({ marginTop: header_height }); }*/

        var BurgerMenu = (function () {
            var $burgerMenu = $header.find('.menu-burger');
            var $text = $burgerMenu.find('.menu-burger__text');

            function activate() {
                $burgerMenu.addClass('active').attr('title', 'Close');
                $text.text('Close');
                $nav.addClass('active');
                $body.addClass('no-scroll');

                var styles = { position: 'fixed' };
                if ($adminBar.length) { styles.top = $adminBar.innerHeight(); }

                $header.css(styles);
                $body.css({ marginTop: $header.innerHeight() });
            }

            function reset() {
                $burgerMenu.removeClass('active').attr('title', 'Menu');
                $text.text('Menu');
                $nav.removeClass('active').find('.active').removeClass('active');
                $body.removeClass('no-scroll');
                $mainTop.removeClass("hide");

                var styles = { position: 'sticky' };
                if ($adminBar.length) { styles.top = $adminBar.innerHeight() };

                $header.css(styles);
                $body.css({ marginTop: 0 });
            }

            $burgerMenu.click(function () {
                var $this = $(this);

                if ($this.hasClass('active')) { reset(); } else { activate(); }
            });

            return {
                close: reset,
                open: activate
            }
        }());

        var DropdownMenus = (function () {
            var $menus = $('.menu');
            var $dropmenus = $menus.find('.dropdown.menu-item');
            var $mobileArrow = $dropmenus.find('.mobile-arrow');

            function toggleDropdown(e) {
                e.preventDefault();

                var $this = $(this);
                var $menuItem = $this.parent();

                if ($menuItem.hasClass('active')) {
                    $menuItem.removeClass('active');
                } else {
                    $menuItem.addClass('active');
                }
            }

            $mobileArrow.click(toggleDropdown);
        }());

        var StickyHeader = (function () {
            if (!$header.hasClass('sticky')) { return; }

            if (window.scrollY) {
                $header.addClass('sticky--scrolled');
            }

            $(window).on('scroll', function () {

                if (window.scrollY) {
                    $header.addClass('sticky--scrolled');

                } else if (window.scrollY === 0) {
                    $header.removeClass('sticky--scrolled');
                }

                if ($adminBar.length) {
                    $header.css({ top: $adminBar.innerHeight() });
                }

            });
        }());

        window.addEventListener('resize', function () {
            $header.css({ position: 'sticky' });
            BurgerMenu.close();

            var styles = { marginTop: window.innerWidth < 960 ? header_height : 0 };

            $nav.css(styles);
        });

        var MegaMenu = (function () {
            var $menus = $('.megamenu');
            var $dropmenus = $menus.find('.megamenu__menu-item--dropdown');
            var $backToMenu = $menus.find('.megamenu-back');
            var $mobileArrow = $dropmenus.find('.megamenu__arrow');
            var $dropdownHeader = $dropmenus.find('.megamenu__link[href="#"]');
            var $mainTop = $('.megamenu-submenu-top--main');
            var $submenus = $menus.find('.megamenu-submenu');
            var $anchorLinks = $menus.find('.megamenu-submenu a');

            if (window.innerWidth > 959) {
                $submenus.css({ top: header_height });
            } else if ($adminBar) {
                $menus.css({ marginTop: $adminBar.innerHeight() });
                $submenus.css({ top: $adminBar.innerHeight() });
            }

            function toggleDropdown(e) {
                e.preventDefault();

                var $this = $(this);
                var $menuItem = $this.parent();

                $dropmenus.not($menuItem).removeClass('active');

                if ($menuItem.hasClass('active')) {
                    $menuItem.removeClass('active');
                } else {
                    $menuItem.addClass('active');
                    // $mainTop.addClass("hide");
                }
            }

            $anchorLinks.click(function () {
                $dropmenus.removeClass('active');
            });

            $mobileArrow.click(toggleDropdown);
            $dropdownHeader.click(toggleDropdown);
            $backToMenu.click(function () {
                var $menu = $(this).closest('.megamenu__menu-item');
                $mainTop.removeClass("hide");
                $menu.removeClass('active');
            });

        }());
        var megaMenuHover = (function () {
            $(".megamenu-submenu__links a").mouseover(function () {
                if ($(this).parent().find('.hover-img').length) {
                    $hoverImage = $(this).parent().find('.hover-img img').attr("src");
                    $(this).closest(".megamenu-submenu").find(".col--img img").attr("src", $hoverImage);

                }
            });
            // $(".megamenu-submenu__links a").mouseout(function () {
            //     if ($(this).parent().find('.hover-img').length) {
            //         var orginalImage = $(this).closest(".megamenu-submenu").find(".img-hide img").attr("src");
            //         console.log(orginalImage);
            //         $(this).closest(".megamenu-submenu").find(".col--img img").attr("src", orginalImage);
            //     }
            // });

        }());
    }());

    var LoadMore = (function () {
        var $loadmore = $('#loadmore');
        if (!$loadmore.length) { return; }

        var $loadmore_text = $loadmore.text();

        $loadmore.click(function () {
            var $this = $(this);
            var $postlist = $this.siblings('.blog-posts');
            var query = WP.query;
            var page = WP.current_page;
            var max = WP.max_page;

            var data = {
                action: 'load_more_posts',
                query: query,
                page: page
            };

            $.ajax({
                url: ajaxURL,
                type: 'post',
                data: data,
                beforeSend: function () {
                    $loadmore.attr('disabled', true).text('Loading Posts');
                },
                error: function (res) {
                    res = JSON.parse(res);
                    console.log(res);
                },
                success: function (posts) {
                    if (posts) {
                        page = WP.current_page++;
                        $loadmore.attr('disabled', false).text($loadmore_text);
                        $postlist.append(posts);
                        LazyLoading.update();

                        if (WP.current_page >= max) { $loadmore.remove(); }
                    } else {
                        $loadmore.remove();
                    }
                }
            });
        });
    }());

    var ImageLinks = (function () {
        var $section = $('.image-links');
        if (!$section.length) { return; }

        var $slider = $section.find('.slider');
        $slider.flickity({
            contain: true,
            cellAlign: 'left',
            groupCells: true,
            pageDots: false,
            watchCSS: true
        });
    }());

    var EventsSlider = (function () {
        var $section = $('.eventsblock');
        if (!$section.length) { return; }

        var $slider = $section.find('.slider');
        $slider.flickity({
            contain: true,
            cellAlign: 'left',
            groupCells: true,
            pageDots: false,
            watchCSS: true
        });
    }());

    var TestimonialSlider = (function () {
        var $section = $('.testimonial-slider');
        if (!$section.length) { return; }

        var $slider = $section.find('.slider');
        $slider.flickity({
            contain: true,
            prevNextButtons: false,
            adaptiveHeight: true
        });
    }());

    var PressSlider = (function () {
        var $section = $('.pressblock');
        if (!$section.length) { return; }

        var $quote_slider = $section.find('.slider--quote');
        var $logo_slider = $section.find('.slider--logo');

        $quote_slider.flickity({
            prevNextButtons: false,
            pageDots: false,
            adaptiveHeight: true,
        });

        $logo_slider.flickity({
            prevNextButtons: false,
            pageDots: false,
            asNavFor: $quote_slider[0]
        });
    }());

    var OpenChat = (function () {
        var $btn = $('.open-chat');
        $btn.click(function () {
            Reamaze.popup();
        })
    }());

    var PlacesAutocomplete = (function () {

        var input = document.querySelector('#address');
        if (!input) { return; }

        var $address = $(input);
        var $city = $('#city');
        var $state = $('#state');
        var $zip = $('#zip-code');

        var options = {
            componentRestrictions: { country: "us" },
            fields: ["address_components"],
            strictBounds: false
        };

        var autocomplete = new google.maps.places.Autocomplete(input, options);

        autocomplete.addListener('place_changed', function () {
            var place = autocomplete.getPlace();

            $city.val('');
            $state.val('');
            $zip.val('');

            var address1 = '';
            var city = '';
            var state = '';
            var postcode = '';
            var country = '';

            place['address_components'].forEach(function (i) {
                console.log(i);
                switch (i.types[0]) {
                    case "street_number": {
                        address1 += i.long_name + ' ';
                        break;
                    }

                    case "route": {
                        address1 += i.short_name;
                        break;
                    }

                    case "postal_code": {
                        postcode = i.long_name;
                        break;
                    }

                    case "locality": {
                        city = i.long_name;
                        break;
                    }

                    case "administrative_area_level_1": {
                        state = i.short_name;
                        break;
                    }
                }
            });

            $address.val(address1);
            $state.val(state);
            $city.val(city);
            $zip.val(postcode);

        });

        $address.on('keypress', function (e) {
            var $autocomplete_searchbox = $('.pac-container');

            setTimeout(function () {

                $autocomplete_searchbox.css({ marginTop: -24 });
            }, 500);

        });

    }());

    var FAQsArchive = (function () {
        var $section = $('.faqs');
        if (!$section.length) { return; }

        var $cats = $section.find('.faqs__categories button');
        var $content = $section.find('.faqs__section');

        console.log($cats);
        $cats.click(function () {
            var $this = $(this);

            $cats.removeClass('active').filter('[data-id="' + $this.data('id') + '"]').addClass('active');
            $content.removeClass('active').filter('[data-id="' + $this.data('id') + '"]').addClass('active');

        });
    }());

    var SingleATC = (function () {

        window.SingleAddToCart = function ($prod, callback) {
            var product = {
                quantity: 1,
                product_id: parseInt($prod.data('pid'))
            };

            var data = {
                action: 'add_to_cart',
                items: [product]
            };

            $.ajax({
                type: "post",
                url: ajaxURL,
                data: data,
                complete: function (res) {
                    callback(res.responseText);
                    if (!$('.header-icon--cart').hasClass('items-in-bag')) {
                        $('.header-icon--cart').addClass('items-in-bag');
                    }
                }
            });
        };

        $(document).on('click', '.single-addtocart', function () {
            var $this = $(this);

            $this.prop('disabled', true).addClass('loading');

            SingleAddToCart($this, function (res) {
                res = JSON.parse(res);
                $('.woocommerce-notices-wrapper').append(res);

                $this.prop('disabled', false).removeClass('loading');

            });
        });
    }());

    var WelcomeVideo = (function () {

        var $welcome_video = $('.welcome-video');

        if (!$welcome_video.length) return;

        var viewCounter = $welcome_video.data('view-counter');

        var videoCounter = localStorage.getItem('welcome_vid_counter');

        console.log('view count is ' + viewCounter);
        console.log('view count is ' + videoCounter);

        if (!videoCounter) {
            localStorage.setItem('welcome_vid_counter', 1);
            return;
        }

        if (videoCounter <= viewCounter) {
            $welcome_video.find('.welcome-video-trigger').trigger('click');
            increaseCount();
        }

        function increaseCount() {

            let cnt = localStorage.getItem('welcome_vid_counter');
            cnt++;
            console.log('count is now ' + cnt);
            localStorage.setItem('welcome_vid_counter', cnt);
        }

    }());

    AOS.init();
});
jQuery(document).ready(function ($) {

    window.AddToCart = function ($products, callback) {
        var products = [];

        $products.map(function (i, elm) {
            var data = {
                quantity: parseInt(elm.value),
                product_id: parseInt(elm.dataset.pid),
                variation_id: parseInt(elm.dataset.id)
            };
            products.push(data);
        });

        var data = {
            action: 'add_to_cart',
            items: products
        };

        $.ajax({
            type: "post",
            url: ajaxURL,
            data: data,
            complete: function (res) {
                callback(res.responseText);
            }
        });
    };

    if (!$('.woocommerce').length) { return; }

    var AccountDashboard = (function () {
        var TotalSpentSummary = (function () {
            var $section = $('.totalspent-summary');
            if (!$section.length) { return; }

            var $range = $section.find('.totalspent-range');
            var $progress = $range.find('.totalspent-range__progress');
            var $cur = $range.find('.totalspent-range__label--cur');
            var $total_spent = parseInt($range.data('cur'));
            var $max = parseInt($range.data('max'));

            var $percent = parseFloat(($total_spent / $max) * 100);

            console.log($percent);

            setTimeout(function () {
                $progress.css({ background: 'linear-gradient(to right, #000 ' + $percent + '%, #ddd 0%)' });
                $cur.css({ left: $percent + '%' }).text('$' + $total_spent);
            }, 1000);
        }());

        var OrderSummaryCard = (function () {
            var $section = $('.ordersummary-card');
            if (!$section.length) { return; }

            var $slider = $section.find('.slider');
            $slider.flickity({
                contain: true,
                cellAlign: 'left',
                pageDots: false
            });
        }());
    }());

    var ReOrder = (function () {
        var $reorder = $('.reorder');
        if (!$reorder.length) { return; }

        $reorder.click(function () {
            var data = {
                action: 'wc_reorder',
                id: $(this).data('order')
            };

            $.ajax({
                type: "POST",
                url: ajaxURL,
                data: data,
                beforeSend: function () {
                    $reorder.addClass('loading');
                },
                success: function (res) {
                    res = JSON.parse(res);
                    $('.woocommerce-notices-wrapper').append(res);
                    $reorder.removeClass('loading');
                }
            });

        });
    }());

    var QtyInput = (function () {
        var $inputs = $('.qty-input');
        if (!$inputs.length) { return; }

        function updateQty() {
            var $qty = $('.product-atc__qty');
            var $items = $('.qty-input__input input');
            var qty = 0;

            $items.each(function () {
                qty = qty + parseInt(this.value);
            });

            var btn_txt = ' (' + parseInt(qty);
            btn_txt += (qty == 1) ? ' Item)' : ' Items)';
            $qty.html(btn_txt);
        }

        $('body').on('change', '.qty-input [type="number"]', function () {
            var $this = $(this);
            var qty = parseInt($this.val());
            var min = parseInt($this.attr('min'));
            var max = parseInt($this.attr('max'));

            if (qty < min) {
                qty = min
            } else if (qty > max) {
                qty = max;
            } else if (qty < 0) {
                qty = 0;
            }

            $this.val(qty);

            updateQty();
        });

        $('body').on('click', '.qty-input [type="button"]', function () {
            var $this = $(this);
            var action = $this.data('action');
            var $qty = $this.parent().find('input');

            var qty = parseInt($qty.val());
            var min = parseInt($qty.attr('min'));
            var max = parseInt($qty.attr('max'));

            qty = action == 'increase' ? qty + 1 : qty - 1;

            if (qty < min) {
                qty = min
            } else if (qty > max) {
                qty = max;
            } else if (qty < 0) {
                qty = 0;
            }

            $qty.val(qty).trigger('change');
        });
    }());

    var ProductDetailAccordions = (function () {
        var $section = $('.product-tabs');
        if (!$section.length) { return; }

        var $accordions = $section.find('.product-tabs__label');

        $accordions.click(function () {
            var $this = $(this);
            $accordions.removeClass('active').filter($this).addClass('active');

        });
    }());

    var ProductPage = (function () {
        $section = $('.single-product');
        if (!$section.length) { return; }

        var $btn = $('.product-atc__btn');

        $btn.click(function () {
            var $products = $section.find('[name="quantity"]').filter(function (i, x) {
                return parseInt(x.value) > 0;
            });

            if (!$products.length) { return; }
            console.log($products);

            $btn.prop('disabled', true).addClass('loading');

            AddToCart($products, function (res) {
                location.href = '/cart/';
            });
        });

        var ProductGallery = (function () {
            var $section = $('.product-gallery');
            if (!$section.length) { return; }

            var $slider = $section.find('.product-gallery__slider');
            $slider.flickity({
                contain: true,
                prevNextButtons: true,
                watchCSS: true,
                cellAlign: 'left'
            });

            // bind event listener first
            $slider.on('ready.flickity', function () {
                if (window.innerWidth < 960) {
                    $.each($slider.find('.desk-only'), function (i, el) {
                        $slider.flickity('remove', $(el))
                    });
                    $slider.flickity('reloadCells');
                }
            });

            var $gallery_slider = $section.find('.gallery-slider');
            $gallery_slider.flickity({
                contain: true,
                groupCells: true,
                pageDots: false,
                prevNextButtons: true,
            });
        }());

        var ProductUpsells = (function () {
            var $section = $('.product-upsells');
            if (!$section.length) { return; }

            var $slider = $section.find('.slider');
            var $prev = $section.find('.slider__prev');
            var $next = $section.find('.slider__next');
            $slider.flickity({
                prevNextButtons: false,
                pageDots: false,
                watchCSS: true,
                contain: true,
                groupCells: true,
                cellAlign: 'left'
            });

            $prev.on('click', function () {
                $slider.flickity('previous', true);
            });

            $next.on('click', function () {
                $slider.flickity('next', true);
            });
        }());

        var ProductSlider = (function () {
            var $section = $('.product-slider');
            if (!$section.length) { return; }

            var $slider = $section.find('.slider');
            var $prev = $section.find('.slider__prev');
            var $next = $section.find('.slider__next');
            $slider.flickity({
                prevNextButtons: false,
                pageDots: false,
                watchCSS: true,
                contain: true,
                groupCells: true,
                cellAlign: 'left'
            });

            $prev.on('click', function () {
                $slider.flickity('previous', true);
            });

            $next.on('click', function () {
                $slider.flickity('next', true);
            });
        }());

        var ProductAccessories = (function () {
            var $section = $('.product-accessories');
            if (!$section.length) { return; }

            var $slider = $section.find('.slider');
            var $prev = $section.find('.slider__prev');
            var $next = $section.find('.slider__next');

            $slider.flickity({
                prevNextButtons: false,
                contain: true,
                cellAlign: 'left',
                groupCells: true
            });

            $prev.on('click', function () {
                $slider.flickity('previous', true);
            });

            $next.on('click', function () {
                $slider.flickity('next', true);
            });
        }());

        var SizeGuide = (function () {
            var $section = $('#sizeguide');
            if (!$section.length) { return; }

            var $trigger = $('.sizeguide__trigger');
            var $slider = $section.find('.slider');

            $trigger.click(function () {

                $slider.flickity({
                    prevNextButtons: false,
                    contain: true,
                    cellAlign: 'left',
                    groupCells: true,
                    adaptiveHeight: true
                });

                $section.addClass('active');
            });
        }());
    }());

    var QuickCheckout = (function () {
        var $section = $('.quick-checkout');
        if (!$section.length) { return; }

        var $prod_details = $section.find('.quick-checkout__details');
        var $items = $section.find('.quick-checkout-summary__items');
        var $subtotal = $section.find('.quick-checkout-summary__subtotal');
        var $summary = $section.find('.quick-checkout-summary');
        var $atc = $section.find('.addtocart');
        var selected = {};

        function updateSummary(callback) {
            var subtotal = 0;

            $items.find('[type="number"]').map(function () {
                subtotal += parseInt(this.value) * parseFloat(this.dataset.price);
            });

            $subtotal.text(new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
            }).format(subtotal));

            if (subtotal == 0) {
                $atc.prop('disabled', true);
            } else {
                $atc.prop('disabled', false);
            }

            callback();
        }

        function removeProduct(pid) {
            var $item = $section.find('[type="number"][data-id="' + pid + '"]');

            $item.val(0).closest('.quick-checkout-item').remove();
            updateSummary(function () {
                $item.closest('.qty-input').removeClass('loading');
                $summary.removeClass('loading');
            });
        }

        $atc.click(function () {
            var $selected_products = $summary.find('.qty-input [type="number"]');

            $atc.prop('disabled', true).addClass('loading');

            AddToCart($selected_products, function () {
                location.href = "/cart/";
            });
        });

        $section.on('click', '.quick-checkout-item__remove', function () {
            removeProduct(this.dataset.id);
        });

        $section.on('change', '.qty-input [type="number"]', function () {
            var $this = $(this);
            var qty = parseInt($this.val());
            if (qty < 0) { return; }

            var $qty_input = $section.find('.qty-input [type="number"]');
            var vID = $this.data('id');

            $qty_input.closest('.qty-input').addClass('loading');
            $summary.addClass('loading');

            var $selectedProd = $items.find('[type="number"][data-id="' + vID + '"]');

            if ($selectedProd.length) {
                $qty_input.filter('[data-id="' + vID + '"]').val(qty);

                updateSummary(function () {
                    $qty_input.closest('.qty-input').removeClass('loading');
                    $summary.removeClass('loading');
                });

            } else {
                var data = {
                    action: 'get_quick_checkout_item',
                    pid: vID,
                    qty: qty
                };

                $.ajax({
                    type: "POST",
                    url: ajaxURL,
                    data: data,
                    beforeSend: function () {},
                    success: function (res) {
                        $items.append(res);
                        updateSummary(function () {
                            $qty_input.closest('.qty-input').removeClass('loading');
                            $summary.removeClass('loading');
                        });
                    }
                });

            }
            selected[vID] = qty;
        });

        var ProductDetails = (function () {
            var $pd_sidebar = $('.product-details');
            var $pd_content = $pd_sidebar.find('.product-details__details');
            var $close_sidebar = $pd_sidebar.find('.product-details__close');

            function closeSidebar() {
                $pd_sidebar.hide();
                $pd_content.html('');

                $('body').removeClass('no-scroll');
            }

            $close_sidebar.click(function () {
                closeSidebar();
            });

            $pd_sidebar.click(function (e) {
                var $this = $(e.target);
                if (!$this.hasClass('product-details')) { return; };
                closeSidebar();
            });

            $prod_details.click(function () {
                var $this = $(this);
                var id = parseInt($this.data('id'));

                var data = {
                    action: 'get_product_details',
                    pid: id
                };

                $('body').addClass('no-scroll');
                $pd_content.html('').addClass('loading');
                $pd_sidebar.show();

                $.ajax({
                    type: "POST",
                    url: ajaxURL,
                    data: data,
                    beforeSend: function () {},
                    success: function (res) {
                        $pd_content.html(res).removeClass('loading');
                        Accordions.update();
                    }
                });
            });

        }());

    }());

    var SwatchPop = (function () {
        var $pop = $('.swatchpop');
        if (!$pop.length) { return; }

        console.log($pop);

        $('body').on('click', '.swatchpop__cat', function () {
            var $this = $(this);

            $('.swatchpop__cat').removeClass('active').filter('[data-cat="' + $this.data('cat') + '"]').addClass('active');

            $('.swatchpop__swatches').removeClass('active').filter('[data-cat="' + $this.data('cat') + '"]').addClass('active');
        });
    }());

    var HoverImage = (function () {
        var $el = $('img[data-hover]');
        if (!$el.length) { return; }

        $el.hover(function (e) {
            var $this = $(e.target);
            var $img_src;

            switch (e.type) {
                case 'mouseenter':
                    $img_src = $this.attr('data-hover');
                    break;

                default:
                    $img_src = $this.attr('data-src');
                    break;
            }

            $this.attr('srcset', $img_src);
            $this.attr('src', $img_src);

        });
    }());
});